import { useParams } from "react-router-dom";
import useGroup from "../../projectGroups/hooks/useGroup";
import { useGetProjectByIdQuery } from "../../projects/projectsSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useProject = () => {
  const navigate = useNavigate();
  const { groupId, projectId } = useParams();
  const { group } = useGroup();
  const { data, ...rest } = useGetProjectByIdQuery(projectId, {
    skip: !projectId,
  });
  const { ids, entities } = data || {};
  let project = ids != null ? entities[ids] : {};
  if (project.id !== projectId) project = {};
  if (Object.keys(project).length === 0 && Array.isArray(group?.projects)) {
    project = group.projects.find((project) => project.id === projectId) || {};
  }

  useEffect(() => {
    if (rest?.error?.status === 500 || rest?.error?.status === 400) {
      navigate(`/${groupId}`);
    }
    // eslint-disable-next-line
  }, [rest?.isError]);

  return { project, ...rest };
};

export default useProject;
