import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useSearchParams from "./useSearchParams";
import { useLocation } from "react-router-dom";
import useOrganizations from "../features/organizations/hooks/useOrganizations";
import useOrganizationGroups from "../features/projectGroups/hooks/useOrganizationGroups";
import useSharedGroups from "../features/projectGroups/hooks/useSharedGroups";
import useMyGroups from "../features/projectGroups/hooks/useMyGroups";
import useGroup from "../features/projectGroups/hooks/useGroup";
import useProject from "../features/projects/hooks/useProject";
import useIssueById from "../features/issues/hooks/useIssueById";
import useMyProfile from "../features/auth/useMyProfile";

const useRefresher = () => {
  const location = useLocation();
  const { groupId, projectId, issueId } = useParams();
  const { getParam } = useSearchParams();
  const [userActive, setUserActive] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(3);
  const refreshTimerRef = useRef();
  const owner = getParam("owner");
  const { refetch: refetchOrganizations } = useOrganizations();
  const { refetch: refetchOrganizationGroups } = useOrganizationGroups(
    owner,
    owner !== "my" && owner !== "shared"
  );
  const { refetch: refetchSharedGroups } = useSharedGroups(true);
  const { refetch: refetchMyGroups } = useMyGroups(true);
  const { refetch: refetchGroup } = useGroup(true);
  const { refetch: refetchProject } = useProject();
  const { refetch: refetchIssue } = useIssueById(issueId);
  const { refetch: refetchProfile } = useMyProfile();

  const invalidateData = () => {
    if (groupId) {
      if (projectId) {
        if (issueId) {
          refetchIssue();
        } else {
          refetchProject();
        }
      } else {
        refetchGroup();
      }
    } else if (location?.pathname === "/profile") {
      refetchProfile();
    } else {
      refetchOrganizations();
      if (owner == null || owner === "shared") {
        refetchSharedGroups();
      } else if (owner === "my") {
        refetchMyGroups();
      } else {
        refetchOrganizationGroups();
      }
    }
  };

  let inactivityTimer;
  const handleMouseMove = () => {
    setUserActive((state) => state || true);
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      setUserActive((state) => (state ? false : state));
    }, 10000);
  };

  const handleWindowBlur = () => {
    setRefreshCounter((state) => Math.max(state - 1, 0));
  };

  const handleWindowFocus = () => {
    setRefreshCounter((state) => (state !== 3 ? 3 : state));
  };

  useEffect(() => {
    clearTimeout(refreshTimerRef.current);
    if (refreshCounter > 0) {
      invalidateData();
      refreshTimerRef.current = setTimeout(() => {
        setRefreshCounter((state) => Math.max(state - 1, 0));
      }, 10000);
    }
    // eslint-disable-next-line
  }, [refreshCounter]);

  useEffect(() => {
    if (userActive) {
      setRefreshCounter((state) => (state !== 3 ? 3 : state));
    } else {
      setRefreshCounter((state) => (state !== 0 ? 0 : state));
    }
    // eslint-disable-next-line
  }, [userActive]);

  useEffect(() => {
    invalidateData();
    setRefreshCounter((state) => (state !== 3 ? 3 : state));
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
      window.removeEventListener("mousemove", handleMouseMove);
    };
    // eslint-disable-next-line
  }, []);

  return false;
};

export default useRefresher;
