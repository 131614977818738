import { useRef, useEffect, useState } from "react";
import useIssue from "./useIssue";
import useProject from "../../projects/hooks/useProject";
import useMarkIssueAsSeen from "./useMarkIssueAsSeen";
import useIssueToggle from "./useIssueToggle";
import useLang from "../../../hooks/useLang";

const useSingleIssue = () => {
  const lang = useLang();
  const footerRef = useRef();
  const footerLoadedRef = useRef(false);
  const [footerLoaded, _setFooterLoaded] = useState(false);
  const setFooterLoaded = (value) => {
    footerLoadedRef.current = value;
    _setFooterLoaded(value);
  };
  const { issue, isLoading } = useIssue();
  const { project } = useProject();
  useMarkIssueAsSeen(issue);
  const { onIssueToggle, isLoading: closeIssueMutationLoading } =
    useIssueToggle(issue);

  let normalizedFrames = {};
  let frames = [];
  if (project?.drawings?.frames) {
    frames = project?.drawings?.frames;
  }
  if (project?.visualizations?.frames) {
    frames = [...frames, ...project?.visualizations?.frames];
  }
  if (project?.obsolete?.frames) {
    const obsoleteFrames = project?.obsolete?.frames.map((frame) => ({
      ...frame,
      obsolete: true,
    }));
    frames = [...frames, ...obsoleteFrames];
  }
  frames.map((frame) => {
    normalizedFrames[frame?.id] = frame;
    return false;
  });

  setTimeout(() => {
    if (!footerLoadedRef.current && footerRef.current) setFooterLoaded(true);
  }, 100);

  useEffect(() => {
    if (footerLoaded && footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  }, [footerLoaded]);

  const toggleButtonLabel =
    issue?.status === "OPEN" ? lang("close_issue") : lang("reopen_issue");

  return {
    footerRef,
    issue,
    isLoading,
    toggleButtonLabel,
    onIssueToggle,
    closeIssueMutationLoading,
    frame: normalizedFrames[issue?.target?.frame_id],
  };
};

export default useSingleIssue;
