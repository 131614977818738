import { useParams } from "react-router-dom";
import { useGetGroupByIdQuery } from "../groupsSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useGroup = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { data, ...rest } = useGetGroupByIdQuery(groupId, {
    skip: !groupId,
  });
  const { ids, entities } = data || {};
  let group = ids != null ? entities[ids] : {};
  if (group.id !== groupId) group = {};

  useEffect(() => {
    if (rest?.error?.status === 500) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [rest?.isError]);

  return { group, ...rest };
};

export default useGroup;
