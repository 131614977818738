import { useEffect, useState } from "react";
import useProject from "../../projects/hooks/useProject";
import useGroup from "../../projectGroups/hooks/useGroup";
import { useParams } from "react-router";

const useOffersOverview = () => {
  const [selectedOffers, setSelectedOffers] = useState();
  const { groupId, projectId } = useParams();
  const { project, isLoading } = useProject();
  const { group, status: groupStatus } = useGroup();

  const incommingOffers = project?.offers?.incoming
    ? project?.offers?.incoming
    : [];
  const outgoingOffers = project?.offers?.outgoing
    ? project?.offers?.outgoing
    : [];

  const activeProjects = group?.projects
    ?.map((project) => {
      return {
        ...project,
        selectedOffers: selectedOffers ? selectedOffers[project.id] : {},
      };
    })
    .filter((project) => !project.frozen);

  const getSmallestAmount = (offers) => {
    let smallestAmount;
    let currency;
    let offerId;
    if (!Array.isArray(offers)) return 0;
    offers.map((offer) => {
      if (
        smallestAmount == null ||
        (smallestAmount != null && offer.amount < smallestAmount)
      ) {
        smallestAmount = offer.amount;
        offerId = offer.id;
      }
      currency = offer.currency;
      return false;
    });
    return [smallestAmount || 0, currency, offerId];
  };

  const getTotalAndCurrency = (projects, offerType) => {
    let total = 0;
    let currency;
    projects.map((project) => {
      const [smallestAmount, currencyOfSmallestAmount] = getSmallestAmount(
        project?.offers[offerType]
      );
      total = total + smallestAmount;
      currency = currencyOfSmallestAmount || currency;
      return false;
    });
    return [total, currency];
  };

  const [incommingTotal, incommingCurrency] = getTotalAndCurrency(
    activeProjects,
    "incoming"
  );
  const [outgoingTotal, outgoingCurrency] = getTotalAndCurrency(
    activeProjects,
    "outgoing"
  );

  useEffect(() => {
    if (groupStatus === "fulfilled") {
      setSelectedOffers((state) => {
        let newState = state != null ? { ...state } : {};
        group?.projects?.map((project) => {
          if (newState[project.id] == null) {
            newState[project.id] = {};
            const [, , incomingOfferId] = getSmallestAmount(
              project?.offers?.incoming
            );
            if (incomingOfferId != null) {
              newState[project.id].incoming = incomingOfferId;
            }
            const [, , outgoingOfferId] = getSmallestAmount(
              project?.offers?.outgoing
            );
            if (outgoingOfferId != null) {
              newState[project.id].outgoing = outgoingOfferId;
            }
          }
          return false;
        });
        return newState;
      });
    }
    // eslint-disable-next-line
  }, [groupStatus]);

  return {
    isGroup: projectId == null,
    groupId,
    project,
    projects: activeProjects || [],
    incommingOffers,
    outgoingOffers,
    isLoading,
    incommingTotal,
    incommingCurrency,
    outgoingTotal,
    outgoingCurrency,
  };
};

export default useOffersOverview;
