import { useMyProfileQuery } from "./authApiSlice";
import useLang from "../../hooks/useLang";

const NotFound = ({ title }) => {
  const lang = useLang();
  const { data: myProfile, isLoading } = useMyProfileQuery();
  const username = isLoading ? "..." : myProfile?.username;
  const email = isLoading ? "..." : myProfile?.primary_email;

  return (
    <>
      <h2>{title.replace("%USERNAME%", username).replace("%EMAIL%", email)}</h2>
      <br />
      <p
        dangerouslySetInnerHTML={{
          __html: lang("get_invitation_message")
            .replace("%APP_OWNER%", 'UAB "Urbano House"')
            .replace(
              "%APP_OWNER_EMAIL%",
              `<a href="mailto:info@urbanohouse.com">info@urbanohouse.com</a>`
            )
            .replace("%APP_OWNER_PHONE%", "+370 699 98954"),
        }}
      ></p>
    </>
  );
};

const ProjectNotFound = () => {
  const lang = useLang();
  return <NotFound title={lang("project_not_found_title")} />;
};

const PageNotFound = () => {
  const lang = useLang();
  return <NotFound title={lang("page_not_found_title")} />;
};

export { ProjectNotFound, PageNotFound };
export default NotFound;
