import { BundleGroup } from "../../components/Group";
import Paragraph, { SecondaryParagraph } from "../../components/Paragraph";
import useLang from "../../hooks/useLang";

const OffersTotal = ({
  incommingTotal,
  outgoingTotal,
  incommingCurrency,
  outgoingCurrency,
  isSmallScreen,
}) => {
  const lang = useLang();
  return (
    <>
      <BundleGroup
        vertical={isSmallScreen}
        style={{ alignItems: "flex-start" }}
      >
        <div
          style={{
            width: isSmallScreen ? "unset" : "calc(50% - var(--spacing-1))",
            display: "flex",
            gap: "var(--spacing-2)",
          }}
        >
          {incommingTotal !== 0 ? (
            <>
              <SecondaryParagraph>{`${lang(
                isSmallScreen
                  ? "incomming_total_vat_excluded"
                  : "total_vat_excluded"
              )}:`}</SecondaryParagraph>
              <Paragraph>{`${incommingTotal} ${incommingCurrency}`}</Paragraph>
            </>
          ) : null}
        </div>
        <div
          style={{
            width: isSmallScreen ? "unset" : "calc(50% - var(--spacing-1))",
            display: "flex",
            gap: "var(--spacing-2)",
          }}
        >
          {outgoingTotal !== 0 ? (
            <>
              <SecondaryParagraph>{`${lang(
                isSmallScreen
                  ? "outgoing_total_vat_excluded"
                  : "total_vat_excluded"
              )}:`}</SecondaryParagraph>
              <Paragraph>{`${outgoingTotal} ${outgoingCurrency}`}</Paragraph>
            </>
          ) : null}
        </div>
      </BundleGroup>
    </>
  );
};

export default OffersTotal;