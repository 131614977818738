const lang = {
  LT: {
    ok: "Gerai",
    cancel: "Atšaukti",
    yes: "Taip",
    no: "Ne",
    submit: "Pateikti",
    close: "Uždaryti",
    dismiss: "Atmesti",
    login: "Prisijungti",
    logout: "Atsijungti",
    merge: "Prijungti",
    error: "Klaida",
    warning: "Įspėjimas",
    caution: "Dėmesio",
    create: "Sukurti",
    overwrite: "Perrašyti",
    overwrite_all: "Perrašyti viską",
    skip: "Praleisti",
    skip_conflicting: "Praleisti konfiktuojančius",
    upload: "Įkelti",
    upload_files: "Įkelti bylas",
    upload_drawings: "Įkelti brėžinius",
    upload_visualizations: "Įkelti vizualizacijas",
    mark: "Žymėti",
    create_group: "Sukurti projektą",
    add_project: "Pridėti gaminį",
    add_member: "Pridėti narį",
    create_issue: "Sukurti klausimą",
    close_issue: "Uždaryti klausimą",
    reopen_issue: "Vėl atidaryti klausimą",
    add_description: "Pridėti aprašymą",
    add_comment: "Rašyti komentarą",
    loading: "Kraunama...",
    searching: "Ieškoma...",
    preparing: "Ruošiama...",
    uploading: "Įkeliama...",
    uploading_do_not_close: "Neuždarykite lango, kol byla įkeliama",
    processing: "Apdorojama...",
    being_replaced: "Perrašoma...",
    no_matches_found: "Nerasta atitikmenų",
    username: "Vartotojo vardas",
    password: "Slaptažodis",
    home: "Projektai",
    project_groups: "Projektai",
    specs: "Techninės specifikacijos",
    issues: "Klausimai",
    offers: "Pasiūlymai",
    orders: "Užsakymai",
    offers_plural: "Pasiūlymai",
    orders_plural: "Užsakymai",
    completed_plural: "Baigti",
    cancelled_plural: "Atšaukti",
    on_hold_plural: "Sustabdyti",
    price_offers: "Tiekimas",
    incomming_offers: "Gauti pasiūlymai",
    outgoing_offers: "Pateiktos kainos",
    vat_included: "su PVM",
    vat_excluded: "be PVM",
    members: "Nariai",
    item_name: "Pavadinimas",
    cabinet: "Korpusas",
    facade: "Fasadas",
    worktop: "Stalviršis",
    handles: "Rankenėlės",
    notes: "Pastabos",
    comment: "Komentaras",
    visualizations: "Vizualizacijos",
    drawings: "Brėžiniai",
    files: "Bylos",
    add_project_title: "Naujas gaminys",
    edit_specifications_title: "Techninės specifikacijos",
    add_member_title: "Naujas narys",
    create_new_group_title: "Naujas projektas",
    create_new_issue_title: "Naujas klausimas",
    close_issue_confirmation_title: "Uždaryti klausimą",
    create_new_comment_title: "Naujas komentaras",
    remove_file_confirmation_title: "Trynimo patvirtinimas",
    remove_member_confirmation_title: "Pašalinimo patvirtinimas",
    remove_file_confirmation_text: "Ar tikrai norite ištrinti pasirinktą bylą?",
    remove_member_confirmation_text:
      "Ar tikrai norite ištrinti pasirinktą dalyvį iš grupės?",
    close_issue_confirmation_text: "Ar tikrai norite uždaryti klausimą?",
    cancel_group_confirmation_title: "Projekto atšaukimas",
    cancel_group_confirmation_text: "Ar tikrai norite atšaukti projektą?",
    remove_project_confirmation_text:
      'Ar tikrai norite pašalinti gaminį "%PROJECT_NAME%", susijusius klausimus ir bylas iš projekto?',
    upload_files_title: "Bylų įkėlimas",
    upload_drawings_title: "Brėžinių įkėlimas",
    upload_visualizations_title: "Vizualizacijų įkėlimas",
    item_name_placeholder: "Pavadinimas...",
    issue_title_placeholder: "Klausimo tema...",
    notes_placeholder: "Pastabos...",
    comment_placeholder: "Komentaras...",
    description_placeholder: "Aprašymas...",
    anonymous: "Nežinomas",
    no_title: "_be_pavadinimo_",
    created_by: "Sukūrė",
    collapse: "Suskleisti",
    open: "Atviras",
    open_plural: "Atviri",
    unseen_plural: "Neskaityti",
    closed: "Uždarytas",
    closed_plural: "Uždaryti",
    inquiry: "Užklausa",
    offer: "Pasiūlymas",
    declined: "Atmestas",
    pending: "Eilėje",
    in_progress: "Vykdomas",
    completed: "Baigtas",
    warranty: "Garantinis",
    cancelled: "Atšauktas",
    deleted: "Ištrintas",
    file_deleted: "Susijusi byla ištrinta",
    on_hold: "Sustabdytas",
    all: "Visi",
    my_projects: "Asmeniniai",
    shared_projects: "Pasidalinti su manim",
    created: "Sukurta",
    modified: "Redaguota",
    and: "ir",
    price: "Kaina",
    price_vat_excluded: "Kaina (be PVM)",
    price_vat_included: "Kaina (su PVM)",
    total_vat_excluded: "Suma (be PVM)",
    total_vat_included: "Suma (su PVM)",
    incomming_total_vat_excluded: "Gautų pasiūlymų suma (be PVM)",
    outgoing_total_vat_excluded: "Pateiktų kainų suma (be PVM)",
    vat: "PVM",
    edit: "Keisti",
    edit_specifications: "Keisti specifikacijas",
    today: "Šiandien",
    yesterday: "Vakar",
    month_01: "Sausio",
    month_02: "Vasario",
    month_03: "Kovo",
    month_04: "Balandžio",
    month_05: "Gegužės",
    month_06: "Birželio",
    month_07: "Liepos",
    month_08: "Rugpjūčio",
    month_09: "Rugsėjo",
    month_10: "Spalio",
    month_11: "Lapkričio",
    month_12: "Gruodžio",
    owner: "Savininkas",
    admin: "Administratorius",
    supplier: "Gamyba",
    customer: "Klientas",
    customer_contacts: "Kliento kontaktai",
    status: "Būsena",
    num: "Nr.",
    add_offer: "Pateikti kainą",
    add_offer_title: "Kainos pasiūlymas",
    file_with_name_exists: "Byla %FILE_NAME% jau egzistuoja.",
    file_with_name_being_uploaded: "Byla %FILE_NAME% šiuo metu įkeliama.",
    remove_file_name_confirmation_text:
      "Ar tikrai norite ištrinti %FILE_NAME%?",
    show_hidden: "Rodyti paslėptus",
    manage_visibility: "Valdyti matomumą",
    visible: "Matomas",
    hidden: "Nematomas",
    page_num: "psl.",
    last_visit: "Lankėsi",
    drag_files_here: "Vilkite bylas čia arba",
    press_upload: "Spauskite įkelti",
    change_project_status_to: "Pakeisti projekto būseną į",
    remove_offer_confirmation_title: "Ištrinti pasiūlymą",
    remove_offer_confirmation_text:
      "Ar tikrai norite ištrinti kainos pasiūlymą?",
    you_dont_have_assigned_project_groups:
      "Vartotojas %USERNAME% (%EMAIL%) neturi priskirtų projektų.",
    project_not_found_title:
      "Projektas nerastas, arba vartotojas %USERNAME% (%EMAIL%) neturi teisės prie jo prisijungti.",
    page_not_found_title:
      "Puslapis nerastas arba vartotojas %USERNAME% (%EMAIL%) neturi teisės prie jo prisijungti.",
    get_invitation_message:
      "Jei manote, kad įvyko klaida, susisiekite su %APP_OWNER% el. paštu %APP_OWNER_EMAIL% arba telefonu Nr. %APP_OWNER_PHONE%.",
    profile: "Profilis",
    customer_details: "Kliento rekvizitai",
    full_name: "Vardas",
    first_name: "Vardas",
    last_name: "Pavardė",
    email: "El. paštas",
    primary_email: "El. paštas pranešimams",
    other_emails: "Kiti el. pašto adresai",
    phone: "Tel. numeris",
    address: "Adresas",
    company: "Įmonė",
    profile_picture: "Profilio atvaizdas",
    edit_profile: "Redaguoti profilį",
    add_email: "Pridėti el. pašto adresą",
    email_confirmed: "El. pašto adresas sėkmingai patvirtintas",
    type_not_selected: "Gaminio tipas",
    choose_type: "Pasirinkti gaminio tipą",
    invitation_sent: "Patvirtinimo nuoroda išsiųsta elektroniniu paštu",
    edit_contacts: "Keisti kontaktus",
    common_files_dir: "Bendros bylos",
  },
};

const useLang = () => {
  const onTranslate = (textToTranslate) => {
    if (typeof textToTranslate !== "string") return "";
    const result = lang.LT[textToTranslate.toLocaleLowerCase()];
    if (result == null) return textToTranslate;
    return result;
  };
  return onTranslate;
};

export default useLang;
