import { NavLink } from "react-router-dom";
import Icon from "../../components/Icon";

import style from "./navigation.module.css";

const HomeLink = () => {
  return (
    <>
      <NavLink to="/" className={style.homeLink}>
        <Icon home size={32} />
      </NavLink>
    </>
  );
};

export default HomeLink;
