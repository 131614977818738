import useGroupTabs from "./hooks/useGroupTabs";
import { HomeLayout } from "../../components/Layout";
import Form from "../../components/Form";
import { PageHeader } from "../../components/Header";
import { PageHeaderGroup } from "../../components/Group";
import useLang from "../../hooks/useLang";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ParamLinkTabs } from "../../components/Tabs";
import MyGroupList from "./MyGroupList";
import SharedGroupList from "./SharedGroupList";
import OrganizationGroupList from "./OrganizationGroupList";
import useRefresher from "../../hooks/useRefresher";

const GroupTabs = () => {
  const lang = useLang();
  useRefresher();
  const {
    owner,
    organizations,
    tabAttributes,
    isLoading,
    isSuccess,
    showTabs,
    hasGroups,
    myProfile,
    errorMessage,
  } = useGroupTabs();
  let content;
  if (isLoading) {
    content = <p>{lang("loading")}</p>;
  } else if (isSuccess && hasGroups) {
    content = (
      <>
        {showTabs ? <ParamLinkTabs tabs={tabAttributes} /> : null}
        <MyGroupList show={owner === "my"} />
        <SharedGroupList show={owner === "shared"} />
        {organizations?.map((organization) => (
          <OrganizationGroupList
            key={organization}
            owner={organization}
            show={organization === owner}
          />
        ))}
      </>
    );
  } else if (isSuccess && !hasGroups) {
    content = (
      <>
        <h2>
          {lang("you_dont_have_assigned_project_groups")
            .replace("%USERNAME%", myProfile.username)
            .replace("%EMAIL%", myProfile.primary_email)}
        </h2>
        <br />
        <p
          dangerouslySetInnerHTML={{
            __html: lang("get_invitation_message")
              .replace("%APP_OWNER%", 'UAB "Urbano House"')
              .replace(
                "%APP_OWNER_EMAIL%",
                `<a href="mailto:info@urbanohouse.com">info@urbanohouse.com</a>`
              )
              .replace("%APP_OWNER_PHONE%", "+370 699 98954"),
          }}
        ></p>
      </>
    );
  } else {
    content = (
      <>
        <h2>{lang("error")}</h2>
        <br />
        <p>{errorMessage}</p>
      </>
    );
  }

  return (
    <>
      <HomeLayout>
        <Form style={{ width: "100%", minWidth: "800px" }} noGap>
          <PageHeader>
            <PageHeaderGroup>
              <h1>{lang("project_groups")}</h1>
            </PageHeaderGroup>
          </PageHeader>
          <ErrorBoundary fallback="Error in group tabs">
            {content}
          </ErrorBoundary>
        </Form>
      </HomeLayout>
    </>
  );
};

export default GroupTabs;
